import revive_payload_client_zmNuVMl1ZU from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_veaonuimzkxlz37dwjs5dpu2pm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_cBF8BIeMkh from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_veaonuimzkxlz37dwjs5dpu2pm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_d5VZmIhMOl from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_veaonuimzkxlz37dwjs5dpu2pm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ZcOMT2aukA from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_veaonuimzkxlz37dwjs5dpu2pm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_fwtLPVt8W4 from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_veaonuimzkxlz37dwjs5dpu2pm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_mWvDJkfhMa from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_veaonuimzkxlz37dwjs5dpu2pm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_HrZ6UO74iY from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_veaonuimzkxlz37dwjs5dpu2pm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_MsEwoR3YOl from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.28.1_typescript@5.4.5_vue@3.5.13_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/site/.nuxt/components.plugin.mjs";
import prefetch_client_G3bzFPR5ZX from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_veaonuimzkxlz37dwjs5dpu2pm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_HlYL3akL4v from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _20_env_612nqPD8GK from "/app/apps/site/plugins/20.env.ts";
import _30_user_jwt_mvBwInWRkI from "/app/apps/site/plugins/30.user-jwt.ts";
import _40_sentry_client_HjOVFNcJVj from "/app/apps/site/plugins/40.sentry.client.ts";
import _50_masonry_wall_FORjha14i9 from "/app/apps/site/plugins/50.masonry-wall.ts";
import _70_google_tag_manger_client_Xc6xP22Ep5 from "/app/apps/site/plugins/70.google-tag-manger.client.ts";
import _80_meta_client_KYedthzPAW from "/app/apps/site/plugins/80.meta.client.ts";
import error_handler_kEP5FliEXj from "/app/apps/site/plugins/error-handler.ts";
export default [
  revive_payload_client_zmNuVMl1ZU,
  unhead_cBF8BIeMkh,
  router_d5VZmIhMOl,
  payload_client_ZcOMT2aukA,
  navigation_repaint_client_fwtLPVt8W4,
  check_outdated_build_client_mWvDJkfhMa,
  chunk_reload_client_HrZ6UO74iY,
  plugin_vue3_MsEwoR3YOl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_G3bzFPR5ZX,
  i18n_HlYL3akL4v,
  _20_env_612nqPD8GK,
  _30_user_jwt_mvBwInWRkI,
  _40_sentry_client_HjOVFNcJVj,
  _50_masonry_wall_FORjha14i9,
  _70_google_tag_manger_client_Xc6xP22Ep5,
  _80_meta_client_KYedthzPAW,
  error_handler_kEP5FliEXj
]